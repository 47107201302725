"use client";
import React from "react";
import styles from "./index.module.scss";
import { ChatAndApplyBtn } from "../../../ChatAndApplyBtn";
import classNames from "classnames";
import { setJobViewCookiesTracker, viewJobSourceTracker } from "components/ReportTracker/util";
import { setCookie } from "helpers/cookies";

const Salary = ({ salaryType, salaryRange, typeSize = 14 }) => {
  return (
    <>
      <span
        style={{
          color: "#1C70EE",
          fontSize: 20,
          fontWeight: 700,
          lineHeight: "28px",
          marginRight: 8
        }}
      >
        {salaryRange}
      </span>
      {salaryType && (
        <span
          style={{
            color: "#000",
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "24px"
          }}
        >
          {`[${salaryType}]`}
        </span>
      )}
    </>
  );
};
const OnlineIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none'>
      <circle cx='5.88281' cy='5' r='4.5' fill='#34C759' stroke='white' />
    </svg>
  );
};
// const OfflineIcon = () => {
//   return (
//     <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
//       <circle cx='6' cy='6' r='6' fill='#F4F5F6' />
//       <circle cx='5.99961' cy='6.0001' r='2.4' fill='#ACAEB1' />
//     </svg>
//   );
// };

const MiniDot = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='2' height='2' viewBox='0 0 2 2' fill='none'>
      <circle cx='0.75' cy='1' r='0.75' fill='#515151' />
    </svg>
  );
};

export const JobItem = ({ item, lang }) => {
  const { text, type_text } = item.salary_info || {};
  const { full_name, avatar, job_title, is_online } = item?.recruiter || {};

  const href = "/" + lang + item.job_url + "?source=company_detail";
  const isOnline = is_online;

  const handleClick = (ev) => {
    ev.preventDefault();
    setJobViewCookiesTracker(setCookie, {
      source: viewJobSourceTracker.companyDetail,
      reco_from: item?.reco_from || null,
      query_id: item?.query_id || null
    });
    window.open(href, "_blank");
  };

  return (
    <a className={styles.jobItemRoot} href={href} target='_blank' onClick={handleClick}>
      <span className={styles.title}>{item.job_title}</span>
      <div
        style={{ position: "relative", height: 32, marginTop: 4, marginBottom: 8, width: "100%" }}
      >
        <Salary salaryType={type_text} salaryRange={text} />
        <div className={styles.chatBtnWrapper}>
          <ChatAndApplyBtn />
        </div>
      </div>

      <div className={styles.jobTagRoot}>
        {(item?.job_tags || []).map((v, index) => {
          return (
            <React.Fragment key={v}>
              {/* {index !== 0 && <MiniDot />} */}
              <span className={styles.jobTagItem}>{v}</span>
            </React.Fragment>
          );
        })}
      </div>
      <div className={styles.line}></div>

      <div className={styles.jobItemBottomWrapper}>
        <div className={styles.avatar}>
          <img src={avatar} />
          {isOnline && <span className={styles.statusWrapper}>{<OnlineIcon />}</span>}
        </div>

        <div style={{ flex: 1, overflow: "hidden" }}>
          <div className={styles.nameWrapper}>
            <div className={styles.name}>{full_name}</div>
            <div className={styles.nameDot} />
            <div className={styles.position}>{job_title}</div>
          </div>
        </div>
      </div>
    </a>
  );
};
